import React, { useState } from "react";
import Pattern from "../../../images/svg/panel_long_1.svg";
import './../index.scss';
import { Parallax } from 'react-parallax';
import { ParallaxCaption } from "../../ParallaxCaption/parallaxCaption"
import { useWindowSize } from "../../../hooks/useWindowSize"

export const Section5 = ({ imgSrc, caption, customSectionClass }) => {
  const [captionShow, setCaptionShow] = useState(false);
  const isMobile = useWindowSize().width <= 768;

  return (
    <div className={`m-main__section m-section__5 ${customSectionClass ? customSectionClass : ''}`}>
      <div className="m-section__svg-pattern"><Pattern /></div>
      <div className="m-section__5-container"
           onMouseEnter={() => setCaptionShow(true)}
           onMouseLeave={() => setCaptionShow(false)}>
        <Parallax bgImage={imgSrc} strength={500} bgImageStyle={{ height: '100%', width: '100%', objectFit: 'cover', transform: 'skew(0, -4deg)' }}>
          <div className="m-section__5-container-parallax-content" />
        </Parallax>
        <ParallaxCaption caption={caption} isVisible={captionShow} />
      </div>
      {!isMobile && <div className="m-section__svg-pattern"><Pattern /></div>}
    </div>
  )
}

