import React from "react";
import './index.scss';
import { CaptionImg } from "../../CaptionImg/captionImg"

export const Section1 = ({ customClass, imgSrc, text, isReversed, imgCaption, centered, customImgStyle, single }) => {
  return (
    <div className={`m-main__section m-section__1 ${customClass}`}>
      <div className={`m-section__1-container ${centered ? 'm-section__1-container--centered' : ''} ${single ? 'm-section__1-container--single' : ''}`}>
        { imgSrc ? (<CaptionImg imgSrc={imgSrc}
                    customClass={`m-section__1-img`}
                    caption={imgCaption}
                    customImgStyle={customImgStyle}
        />)
          : <div></div>
        }
        <p className={`m-main__section-text ${isReversed ? 'm-section__1--reverse' : ''}`}
           dangerouslySetInnerHTML={{ __html: text }}></p>
      </div>
    </div>
  )
}
