import React from "react";
import './index.scss';

export const ParallaxCaption = ({ caption, customClass, isVisible }) => {

  return (
    <div className={
      `m-parallax-caption__container
      ${customClass ? customClass : ''}
      ${isVisible ? 'm-parallax-caption__container--visible' : ''}`}

    >
      <div className={`m-parallax-caption__text-container`}>
        <p className="m-main__section-text m-parallax-caption__text">{caption}</p>
      </div>
    </div>
  )
}
