import React from "react";
import Pattern from "../../../images/panel_long_1.png";
import './index.scss';
import { CaptionImg } from "../../CaptionImg/captionImg"

export const Section3 = ({ imgLeftSrc, imgRightSrc, txtLeft, txtRight, isExpanded, imgRightFull, imgFull, imgLeftCaption, imgRightCaption, customSectionClass }) => {
  return (
    <div className={`m-main__section m-section__3 ${customSectionClass ? customSectionClass : ''}`}>
      <div className={'m-section__3-bg'} style={{ backgroundImage: `url(${Pattern})` }}>
      </div>
      <div className="m-section__3-content">
        <div className="m-section__3-left">
          <p className="m-main__section-text m-section__3-up">
            {txtLeft}
          </p>
          <CaptionImg imgSrc={imgLeftSrc}
                      customClass={`m-section__3-img ${imgRightFull ? 'm-section__3-img--full' : ''}`}
                      caption={imgLeftCaption}
          />
          {isExpanded &&
            <p className="m-main__section-text m-section__3-down m-section__3--hidden">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium asperiores, cum debitis deleniti ea earum error esse expedita fugiat inventore ipsam, iste magnam modi natus nesciunt odit officiis provident quae quasi qui recusandae rerum tempore temporibus ullam unde vel veritatis. Ab commodi cumque deleniti est ipsa natus nemo reprehenderit sunt.
            </p>
          }
        </div>
        <div className="m-section__3-right">
          {isExpanded &&
          <p className="m-main__section-text m-section__3-down m-section__3--hidden">
            -
          </p>
          }
          <CaptionImg imgSrc={imgRightSrc}
                      customClass={
                        `m-section__3-img
                        ${!isExpanded ? 'm-section__3-img--smaller' : ''}
                        ${imgFull ? 'm-section__3-img--full' : 'm-section__3-img--smaller'}`
                      }
                      caption={imgRightCaption}
          />
          <p className="m-main__section-text m-section__3-down">
            {txtRight}
          </p>
        </div>
      </div>
    </div>
  )
}
