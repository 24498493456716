import React from 'react';
import { StarMenu } from "./../Header/StarMenu";
import './index.scss';
import { graphql, useStaticQuery, Link } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import { getResource } from "../../utils/getResource"

export const PageNavigation = ({ prevOnly, nextOnly, prevItem, nextItem }) => {
  const intl = useIntl();
  const locale = intl.locale !== "pl" ? `${intl.locale}` : "pl";

  const pageNavigationData = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "exhibition-nav-item"}}}) {
        nodes {
          frontmatter {
            title
            language
            content
            pageUrl
          }
        }
      }
    }
  `)
  const menuResources = pageNavigationData.allMarkdownRemark.nodes.filter(item => item.frontmatter.language === locale);

  return (
    <div className={`
      m-page-navigation__container
      ${prevOnly ? 'm-page-navigation__container--left' : ''}
      ${nextOnly ? 'm-page-navigation__container--right' : ''}`}
    >
      {!nextOnly && (
        <div className="m-page-navigation__item m-page-navigation__left">
          <Link className="m-page-navigation__link" to={`/${locale}${getResource(menuResources, prevItem).pageUrl}`} />
          <StarMenu />
          <div className="m-page-navigation__text">
            <p className="m-page-navigation__text-content">{getResource(menuResources, 'exhibition-previous').content}:</p>
            <p className="m-page-navigation__text-content m-page-navigation__text-content-page">{getResource(menuResources, prevItem).content}</p>
          </div>
        </div>
      )}
      {!prevOnly && (<div className="m-page-navigation__item m-page-navigation__right">
        <Link className="m-page-navigation__link" to={`/${locale}${getResource(menuResources, nextItem).pageUrl}`} />
        <div className="m-page-navigation__text">
          <p className="m-page-navigation__text-content">{getResource(menuResources, 'exhibition-next').content}:</p>
          <p className="m-page-navigation__text-content m-page-navigation__text-content-page">{getResource(menuResources, nextItem).content}</p>
        </div>
        <StarMenu />
      </div>)}
    </div>
  );
}
