import React from "react";
import './index.scss';

export const CaptionImg = ({ imgSrc, caption, customClass, customImgStyle }) => {

  return (
    <div className={`m-caption-img__container ${customClass}`}>
      <img className="m-caption-img__photo" src={imgSrc} style={customImgStyle}/>
      {caption && (
        <div className={`m-caption-img__text-container`}>
          <p className="m-main__section-text m-caption-img__text">{caption}</p>
        </div>
      )}
    </div>
  )
}
