import React from 'react';
import './index.scss';

export const ChapterTitle = ({ text, bgImage }) => {
  return (
    <div className="m-section__chapter-title"
         // style={{ background: `linear-gradient(to bottom, transparent 65%, #000f1c), url(${bgImage}) center no-repeat` }}
         style={{ backgroundImage: `linear-gradient(to bottom, transparent 65%, #000f1c), url(${bgImage})` }}
    >
      <h1 className="m-main__section-title m-section__chapter-title-text">{text}</h1>
    </div>
  )
}
