import React from "react"
import './index.scss';
import { StarMenu } from "../../Header/StarMenu"
import { Parallax } from "react-parallax"
import { useWindowSize } from "../../../hooks/useWindowSize"
import { CaptionImg } from "../../CaptionImg/captionImg"

export const Section2 = ({ bgImg, text, imgSrc, isReversed, starReversed, option, imgFull, caption, movieSrc, customSectionClass }) => {
  const isMobile = useWindowSize().width <= 768;

  return (
    <div className={`m-main__section m-section__2 ${customSectionClass}`}>
      { !isMobile && (<Parallax bgImage={bgImg} strength={500} /> )}
      { !isMobile && (
          <div className="m-main__section-2-icon m-main__section-2-icon-top">
          <StarMenu />
        </div>
      )}
      {!isMobile && (
        <div className={`m-main__section-2-icon m-main__section-2-icon-bottom ${starReversed ? 'm-main__section-2-icon-bottom--left' : ''}`}>
        <StarMenu />
      </div>
      )}
      {option === 'twoColumns' && (
        <div className="m-section__container-grid">
          <div className={`m-section__2ab-container m-section__2ab-item m-section__2ab-item-img ${imgFull ? 'm-section__2ab-item-img--full' : ''}`}>
            <CaptionImg imgSrc={imgSrc}
                        customClass={`m-section__2ab-img`}
                        caption={caption}
            />
          </div>
          <p className={`m-main__section-text m-section__2ab-item ${isReversed ? 'm-section__2--reverse' : ''}`}>
            {text}
          </p>
        </div>
      )}
      { option === 'imgOnly' && (
        <div className={`m-section__2c-container`}>
          <CaptionImg imgSrc={imgSrc}
                      customClass={`m-section__2c-img`}
                      caption={caption}
          />
        </div>
      )}
      { option === 'textOnly' && (
        <div className="m-section__2d-container">
          <p className="m-main__section-text">
            {text}
          </p>
        </div>
      )}
      { option === 'movie' && (
        <div className="m-section__2-movie-container">
          <iframe width="100%" height="100%" src={movieSrc}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen>
          </iframe>
        </div>
      )}
    </div>
  )
}
